<template>
	<v-card elevation="0">
		<v-card-title
			class="py-3 font-weight-bold"
			:class="{ 'pa-0': isSmAndDown }"
			style="position: relative; width: 100%"
		>
			<div
				class="align-center"
				style="width: 100%"
				:class="isSmAndDown ? 'd-block ' : 'd-flex justify-space-between'"
			>
				<v-btn
					icon
					class="px-0"
					:to="{ name: 'mypage-post-contents-me' }"
					:small="isSmAndDown"
				>
					<v-icon :small="isSmAndDown">mdi-chevron-left</v-icon>
				</v-btn>
				<span :class="{ 'text-base': isSmAndDown }">내 콘텐츠</span>
				<div>
					<div
						class="d-flex"
						style="gap: 3px"
						:class="{ 'mt-2 flex-wrap': isSmAndDown }"
					>
						<v-select
							item-text="name"
							item-value="value"
							:items="dateRangeGroups"
							label="기간 조회"
							:value="selectedDateRange"
							:disabled="isLoading"
							@change="changedDateRange"
							style="max-width: 110px"
							hide-details
							class="ml-1"
							dense
							outlined
						/>
						<v-select
							item-text="name"
							item-value="value"
							:items="typeGroups"
							:value="selectedType"
							:disabled="isLoading"
							@change="changeType"
							label="콘텐츠 타입"
							style="max-width: 138px"
							hide-details
							class="ml-1"
							dense
							outlined
						/>
						<v-select
							item-text="name"
							item-value="value"
							:items="subjectLoungeTypeGroups"
							:value="selectedSubjectLoungeType"
							:disabled="isLoading"
							@change="changeSubjectLoungeType"
							label="라운지 위치"
							style="max-width: 138px"
							hide-details
							dense
							outlined
							v-if="selectedType === postType.CONTEST2.value"
						/>
						<v-select
							item-text="name"
							item-value="value"
							:items="approvalStatusGroups"
							:value="selectedApprovalStatus"
							:disabled="isLoading"
							@change="changeApprovalStatus"
							label="승인여부"
							style="max-width: 110px"
							hide-details
							class="ml-1"
							dense
							outlined
						/>

						<v-btn
							@click="inquiry"
							color="primary"
							:loading="isLoading"
							:disabled="isLoading"
						>
							조회
						</v-btn>
					</div>

					<div class="d-flex align-end mt-1" v-if="isOpenDateRange">
						<date-picker-dialog
							:label="'시작날짜'"
							:date-value.sync="beginDate"
						/>
						<date-picker-dialog :label="'끝날짜'" :date-value.sync="endDate" />
						<v-btn small @click="inquiryDate" color="primary">조회</v-btn>
					</div>
				</div>
			</div>
			<div class="d-flex justify-end" style="width: 100%">
				<div v-if="contentMarketUserStatistics">
					<span class="text-sm">
						<span class="text-xs">판매</span>
						<span v-if="contentMarketUserStatistics" class="ml-1">
							{{ contentMarketUserStatistics.countAmount }}
						</span>
						<span class="text-xs">건</span>
					</span>
					<span class="mx-1 text-sm">/</span>
					<span class="text-sm">
						<span class="text-xs">최고가</span>
						<span v-if="contentMarketUserStatistics" class="ml-1">
							{{ contentMarketUserStatistics.maxAmount | commaFormat }}
						</span>
						<span class="text-xs">원</span>
					</span>
					<span class="mx-1 text-sm">/</span>
					<span class="text-sm">
						<span class="text-xs">총</span>
						<span v-if="contentMarketUserStatistics" class="ml-1">
							{{ contentMarketUserStatistics.sumAmount | commaFormat }}
						</span>
						<span class="text-xs">원</span>
					</span>
				</div>
			</div>
		</v-card-title>
		<v-divider />
		<div class="grid-container" ref="scrollComponent" v-if="posts.length > 0">
			<v-card
				v-for="post in posts"
				:key="post.id"
				class="mb-5 grid-item cursor-pointer"
			>
				<div
					class="px-3 d-flex align-center justify-space-between"
					style="background-color: white; width: 100%"
					:class="isSmAndDown ? 'px-1 py-0 text-xs pt-1' : 'pa-2'"
				>
					<div
						style="
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						"
						:style="isSmAndDown ? 'width: 70%;' : 'width: 100%;'"
					>
						<v-avatar :size="isSmAndDown ? 14 : 26">
							<v-img
								:src="
									post.writerUser.publicPictureUrl ||
									require('@/assets/images/avatars/default.svg')
								"
							/>
						</v-avatar>
						<span class="font-weight-bold">
							{{ post.writerUser.uid }}
						</span>
					</div>
					<div class="actions">
						<v-btn
							small
							text
							@click="editMarketPost(post.id)"
							:icon="isSmAndDown"
						>
							<template v-if="isSmAndDown">
								<v-icon small>mdi-arrow-expand</v-icon>
							</template>
							<template v-else>자세히 보기</template>
						</v-btn>
					</div>
				</div>
				<market-page-post-contents-swiper :post="post" :can-download="true" />
				<div class="wrapper-content">
					<div class="d-flex justify-space-between">
						<div class="font-weight-bold" @click="editMarketPost(post.id)">
							<span>
								{{
									post.subject && post.subject.lounge
										? resoloveSubjectLoungeType(post.subject.lounge)
										: resolvePostType(post.type)
								}}
								{{ post.postingNumber }}
							</span>
							<span v-if="post.subject" class="mx-1">&#62;</span>
							<span v-if="post.subject">{{ post.subject.title }}</span>
						</div>
						<div v-if="post.comments.length > 0">
							<post-comments-menu
								:post-id="post.id"
								:post-comments-count="post.comments.length"
								:unread-post-comments-count="post.unreadPostCommentsCount"
							/>
						</div>
					</div>
					<div class="content" v-html="post.content"></div>
				</div>
			</v-card>
		</div>
		<div v-else class="text-center" style="height: 100vh; padding-top: 25px">
			조회된 데이터가 없습니다.
		</div>
		<div class="fixed" style="bottom: 0">
			<v-pagination
				:value="currentPage"
				:disabled="isLoading"
				:length="totalPages"
				@input="handlePageChange"
				:total-visible="isSmAndDown ? 7 : 10"
			/>
		</div>

		<my-page-post-contents-edit-dialog
			:post-id="selectedPostId"
			:is-open-my-page-post-contents-edit-dialog.sync="
				isOpenMyPagePostContentsEditDialog
			"
			@is-fetch-post-my-post="isFetchPostMyPost = $event"
		/>
	</v-card>
</template>
<script>
import { ref, reactive, watch, onMounted, computed } from '@vue/composition-api'

import dayjs from 'dayjs'

import {
	error,
	postType,
	subjectLoungeType,
	contentsMarketStatus,
} from '@/helpers'
import store from '@/store'
import {
	resolveContentsMarketStatus,
	resolveContentsMarketStatusVariant,
} from '@core/utils/filter'
import { useRouter, getVuetify } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'
import { resolvePostType, resoloveSubjectLoungeType } from '@/filter'

import MarketPostService from '@/services/MarketPostService'

import PostCommentsMenu from './components/PostCommentsMenu.vue'
import MyPagePostContentsEditDialog from './MyPagePostContentsEditDialog.vue'

import DatePickerDialog from '@/components/common/DatePickerDialog.vue'
import MarketPagePostContentsSwiper from './components/MarketPagePostContentsSwiper.vue'

const ITEMS_PER_PAGE = 9
const CONTENT_MAX_LENGTH = 90

export default {
	components: {
		DatePickerDialog,
		PostCommentsMenu,
		MyPagePostContentsEditDialog,
		MarketPagePostContentsSwiper,
	},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const posts = ref([])
		const beginDate = ref('')
		const endDate = ref('')
		const totalPages = ref(0)
		const currentPage = ref(0)
		const isLoading = ref(false)
		const selectedPostId = ref(null)
		const selectedDateRange = ref('')
		const isFetchPostMyPost = ref(false)
		const selectedApprovalStatus = ref('')
		const itemsPerPage = ref(ITEMS_PER_PAGE)
		const isOpenMyPagePostContentsEditDialog = ref(false)
		const contentMarketUserStatistics = ref({
			countAmount: 0,
			maxAmount: 0,
			sumAmount: 0,
		})
		const approvalStatusGroups = ref([
			{
				name: '전체',
				value: '',
			},
			...Object.values(contentsMarketStatus),
		])
		const typeGroups = ref([
			{
				name: '전체',
				value: '',
			},
			postType.CONTEST2,
			postType.CONTEST2_DONA,
		])
		const subjectLoungeTypeGroups = ref([
			{
				name: '전체',
				value: '',
			},
			subjectLoungeType.MIR_YONGSAN,
			subjectLoungeType.MIR_UIJEONGBU,
			subjectLoungeType.MIR_JEONJU,
		])
		const selectedType = ref('')
		const selectedSubjectLoungeType = ref('')
		const breakpoints = ref({
			600: {
				fixedHeight: '320px',
				arrowsOutside: false,
			},
			959: {
				arrowsOutside: false,
			},
		})
		const isOpenDateRange = ref(false)
		const dateRangeGroups = reactive([
			{
				name: '전체',
				value: '',
			},
			{
				name: '1주일',
				value: 0.25,
			},
			{
				name: '1개월',
				value: 1,
			},
			{
				name: '3개월',
				value: 3,
			},
			{
				name: '직접입력',
				value: 0,
			},
		])
		const profile = computed(() => store.getters['user/getMeDetail'])

		const init = () => {
			beginDate.value = ''
			endDate.value = ''
			currentPage.value = 0
			posts.value = []
		}

		const getAllMeDetail = async () => {
			try {
				isLoading.value = true

				const data = await MarketPostService.getAllMeDetail({
					limit: itemsPerPage.value,
					page: currentPage.value,
					orderBy: 'createdAt',
					order: 'desc',
					...(selectedApprovalStatus.value && {
						result: selectedApprovalStatus.value,
					}),
					...(beginDate.value &&
						beginDate.value !== error.INVALID_DATE.value && {
							begin_date: dayjs(beginDate.value)
								.utc()
								.format()
								.toString()
								.replace('T', ' ')
								.replace('Z', ''),
						}),
					...(endDate.value &&
						endDate.value !== error.INVALID_DATE.value && {
							end_date: dayjs(endDate.value)
								.utc()
								.format()
								.toString()
								.replace('T', ' ')
								.replace('Z', ''),
						}),
					...(selectedType.value && { type: selectedType.value }),
					...(!selectedType.value && {
						types: `${postType.CONTEST2.value},${postType.CONTEST2_DONA.value}`,
					}),
					...(selectedSubjectLoungeType.value && {
						subject_lounge: selectedSubjectLoungeType.value,
					}),
					published: true,
				})

				const tempPosts = []
				for (const post of data.posts) {
					if (post.content && post.content.trim().length > CONTENT_MAX_LENGTH) {
						post.content = post.content.substring(0, CONTENT_MAX_LENGTH)
						post.content += `<a href="javascript:void(0);" class="ml-1">…더보기</a>`
					}

					const _post = {
						...post,
						unreadPostCommentsCount: post.comments.filter(
							e => !e.readUser && e.user.id !== profile.value.id,
						).length,
					}
					tempPosts.push(_post)
				}

				posts.value = tempPosts
				totalPages.value = parseInt(data.totalPages || 0)

				contentMarketUserStatistics.value =
					posts.value[0]?.contentMarketUserStatistics
			} catch (e) {
				console.error(e)
				warningSwal('포스트를 가져오는데 문제가 발생했습니다')
			} finally {
				isLoading.value = false
			}
		}

		const changedDateRange = async dateRange => {
			if (dateRange === 0) {
				beginDate.value = ''
				endDate.value = ''

				isOpenDateRange.value = !isOpenDateRange.value
				return
			}
			isOpenDateRange.value = false

			if (dateRange) {
				let type = ''
				const currentDatetime = dayjs().format('YYYY-MM-DD  23:59:59')
				if (dateRange > 0.25) {
					type = 'month'
				} else {
					type = 'week'
					dateRange = 1
				}

				beginDate.value = dayjs(currentDatetime)
					.subtract(dateRange, type)
					.format('YYYY-MM-DD 00:00:01')
				endDate.value = currentDatetime
			}
		}

		const changeApprovalStatus = async status => {
			selectedApprovalStatus.value = status
		}

		const changeType = async type => {
			selectedType.value = type
		}

		const changeSubjectLoungeType = async subjectLoungeType => {
			selectedSubjectLoungeType.value = subjectLoungeType
		}

		const inquiryDate = async () => {
			currentPage.value = 0
			posts.value = []

			beginDate.value = dayjs(beginDate.value).format('YYYY-MM-DD 00:00:01')
			endDate.value = dayjs(endDate.value).format('YYYY-MM-DD 23:59:59')

			await getAllMeDetail()
		}

		const inquiry = async () => {
			init()
			await getAllMeDetail()
		}

		const editMarketPost = postId => {
			selectedPostId.value = postId
			isOpenMyPagePostContentsEditDialog.value =
				!isOpenMyPagePostContentsEditDialog.value
		}

		const scrollToTop = () => {
			window.scrollTo(0, 0)
		}

		const handlePageChange = value => {
			currentPage.value = value

			scrollToTop()
			getAllMeDetail()
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => isFetchPostMyPost.value,
			currentValue => {
				if (currentValue) {
					router.go()
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => route.value.params.postId,
			currentValue => {
				if (currentValue) {
					editMarketPost(currentValue)
				}
			},
			{
				immediate: true,
			},
		)

		onMounted(() => {
			getAllMeDetail()
		})

		return {
			posts,
			beginDate,
			endDate,
			selectedDateRange,
			itemsPerPage,
			isLoading,
			isOpenMyPagePostContentsEditDialog,
			breakpoints,
			selectedPostId,
			isFetchPostMyPost,
			isOpenDateRange,
			dateRangeGroups,
			contentsMarketStatus,
			approvalStatusGroups,
			selectedApprovalStatus,
			contentMarketUserStatistics,
			typeGroups,
			postType,
			subjectLoungeTypeGroups,
			selectedType,
			selectedSubjectLoungeType,
			isSmAndDown,
			currentPage,
			totalPages,

			resolvePostType,
			resoloveSubjectLoungeType,
			resolveContentsMarketStatus,
			resolveContentsMarketStatusVariant,

			changeSubjectLoungeType,
			editMarketPost,
			changeApprovalStatus,
			changedDateRange,
			changeType,
			inquiry,
			inquiryDate,
			handlePageChange,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.hiddenText {
		display: none;
	}
}

.grid-container {
	display: grid;
	gap: 12px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;
	overflow-y: scroll;

	@media (max-width: 960px) {
		gap: 8px;
	}

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 6px;
	}

	@media (max-width: 595px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 3px;
	}

	.grid-item {
		.status-chip {
			position: absolute;
			right: 5px;
			top: 5px;
		}

		@media (max-width: 960px) {
			min-height: 380px;
		}

		@media (max-width: 768px) {
			min-height: 275px;
		}

		@media (max-width: 595px) {
			min-height: 215px;
		}

		@media (max-width: 414px) {
			min-height: 145px;
		}

		::v-deep .v-skeleton-loader__list-item-avatar {
			.v-skeleton-loader__avatar {
				@media (max-width: 571px) {
					height: 15px;
					width: 15px;
				}
			}
		}
	}
}

.fixed {
	right: 12px;
	overflow-y: hidden;
	overflow-x: auto;
	background-color: white;
	z-index: 99;
	position: sticky;

	@media (max-width: 571px) {
		height: 102px;
	}
}

.wrapper-content {
	font-size: 14px;
	padding: 8px 12px;

	@media (max-width: 674px) {
		padding: 6px 8px 2px;
	}

	.content {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
</style>
