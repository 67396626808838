<template>
	<swiper ref="swiperRef" class="swiper" :options="swiperOption">
		<swiper-slide
			v-for="(file, index) in virtualData.slides"
			:key="file.id"
			:virtual-index="file.id"
			:style="{ left: virtualData.offset + 'px' }"
		>
			<v-tooltip bottom v-if="canDownload">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						x-small
						absolute
						v-bind="attrs"
						v-on="on"
						color="primary"
						@click.stop="downloadFile(file)"
						style="left: 5px; top: 5px; z-index: 99"
					>
						<v-icon small>mdi-download</v-icon>
					</v-btn>
				</template>
				<span>개별 다운로드{{ index }}</span>
			</v-tooltip>

			<v-chip
				color="error"
				class="status-chip"
				style="top: 30px; z-index: 99"
				@click.stop="$emit('open-sheet', post.duplicatePosts)"
				v-if="
					post.duplicatePosts &&
					post.duplicatePosts.length > 0 &&
					post.originPost &&
					post.originPost.id !== post.id
				"
			>
				중복 {{ post.duplicatePosts.length }}건
			</v-chip>
			<v-chip
				small
				:color="resolveContentsMarketStatusVariant(post.result)"
				class="status-chip"
			>
				<span
					:class="{
						'd-none':
							post.result === contentsMarketStatus.APPROVED.value &&
							isSmAndDown,
					}"
				>
					{{ resolveContentsMarketStatus(post.result) }}
				</span>
				<span
					v-if="post.result === contentsMarketStatus.APPROVED.value"
					class="ml-1"
				>
					<strong>{{ post.resultJson.payment | commaFormat }}</strong>
					원
				</span>
			</v-chip>

			<v-chip small absolute right style="top: 40px" v-if="!isImage(file.url)">
				<v-icon>mdi-movie-play-outline</v-icon>
			</v-chip>
			<div
				v-if="isImage(file.url)"
				class="text-center cursor-pointer content-wrapper"
			>
				<img
					v-lazyload
					class="swiper-lazy content"
					:data-src="`${renderUrl(file.url)}`"
					@error="$event.target.src = file.url"
					@click="$emit('open-media-dialog', file.url)"
					style="height: 300px"
					loading="lazy"
				/>
			</div>
			<div class="content-wrapper" v-else>
				<video
					style="width: 100%; height: 300px"
					controls
					controlsList="nodownload"
				>
					<source :src="file.url" class="content" />
				</video>
			</div>
			<v-btn small text class="arrow-button-first" @click="moveToFirst()">
				처음
			</v-btn>
			<v-btn small text class="arrow-button-last" @click="moveToLast()">
				끝
			</v-btn>
		</swiper-slide>
		<div class="swiper-pagination" slot="pagination"></div>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</swiper>
</template>
<script>
import { ref, watch, computed, nextTick, onMounted } from '@vue/composition-api'

import {
	resolveContentsMarketStatus,
	resolveContentsMarketStatusVariant,
} from '@core/utils/filter'
import { getVuetify } from '@core/utils'
import { contentsMarketStatus } from '@/helpers'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		post: {
			type: Object,
		},
		canDownload: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, _) {
		const $vuetify = getVuetify()
		const swiperRef = ref(null)
		const virtualData = ref({
			slides: [],
		})
		const swiperOption = ref({
			slidesPerView: 1,
			spaceBetween: 10,
			loop: false,
			lazy: {
				loadPrevNext: true,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'fraction',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		})

		const toDataURL = async url => {
			const blob = await fetch(url).then(res => res.blob())
			return URL.createObjectURL(blob)
		}

		const createDownloadLink = async file => {
			const link = document.createElement('a')
			link.href = await toDataURL(file.url)
			link.download = file.fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}

		const downloadFile = async postFile => {
			await createDownloadLink(postFile)
		}

		const isImage = url => {
			return url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
		}

		const renderUrl = fileUrl => {
			if (fileUrl.indexOf('https://factory-data-history.') > -1) {
				return (
					fileUrl.replace(
						'https://factory-data-history.',
						'https://factory-data-history-600.',
					) +
					'?t=' +
					Date.now()
				)
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const moveToFirst = () => {
			swiperRef.value.$swiper.slideTo(0)
		}
		const moveToLast = () => {
			const index = props.post.files.length - 1
			swiperRef.value.$swiper.slideTo(index)
		}

		watch(
			() => props.post,
			currentvalue => {
				if (currentvalue) {
					swiperOption.value.virtual = {
						slides: props.post.files,
						renderExternal: data => {
							virtualData.value = { ...data }
						},
					}

					if (swiperRef.value) {
						swiperRef.value.initSwiper()

						swiperRef.value.$swiper.slideTo(0)
					}
				}
			},
			{
				immediate: true,
			},
		)

		onMounted(() => {
			nextTick(() => {
				swiperRef.value.initSwiper()
			})
		})

		return {
			swiperRef,
			swiperOption,
			virtualData,
			isSmAndDown,
			moveToFirst,
			moveToLast,
			contentsMarketStatus,

			isImage,
			renderUrl,
			resolveContentsMarketStatus,
			resolveContentsMarketStatusVariant,

			downloadFile,
		}
	},
}
</script>
<style lang="scss" scoped>
.status-chip {
	position: absolute;
	right: 5px;
	top: 5px;
}

.content-wrapper {
	padding-bottom: 16px;
	img {
		margin: 0 auto;
	}
}

.arrow-button-first,
.arrow-button-last {
	position: absolute;
	bottom: -4px;
}
.arrow-button-first {
	left: 0px;
}
.arrow-button-last {
	right: 0px;
}

.swiper-button-prev,
.swiper-button-next {
	color: #bfbfbf;
}
.swiper-pagination {
	position: absolute;
	left: 50%;
	bottom: 12px;
	transform: translate(-50%);
	margin: 0;
	display: inline-block;
	width: 25%;
	min-width: 100px;
	max-width: 100%;
	background-color: rgb(217, 217, 217, 0.5);
	text-align: center;
	padding: 6px 4px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: black;
	font-weight: 500;
}
</style>
