<template>
	<v-dialog
		ref="dateDialog"
		v-model="dateModal"
		:return-value.sync="date"
		persistent
		width="290px"
	>
		<template v-slot:activator="{ on }">
			<v-text-field
				v-model="dateValue"
				:label="`${label}`"
				prepend-icon="mdi-calendar"
				hide-details
				readonly
				v-on="on"
				style="width: 125px"
				class="pt-0"
			/>
		</template>
		<v-date-picker v-model="date" scrollable locale="ko-kr">
			<v-spacer />
			<v-btn color="primary" @click="cancel">취소</v-btn>
			<v-btn color="primary" @click="set">확인</v-btn>
		</v-date-picker>
	</v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
	props: {
		label: {
			type: String,
			required: true,
		},
		dateValue: {
			type: String,
			required: true,
		},
	},
	setup(props, { emit }) {
		const date = ref('')
		const dateModal = ref(false)
		const dateDialog = ref(null)

		const set = () => {
			emit('update:date-value', date.value)
			dateDialog.value.save(date.value.substring(0, 10))
		}

		const cancel = () => {
			dateModal.value = false
			emit('update:date-value', '')
		}

		watch(
			() => props.dateValue,
			currentValue => {
				if (currentValue) {
					emit('update:date-value', date.value.substring(0, 10))
				}
			},
		)

		return {
			date,
			dateModal,
			dateDialog,

			set,
			cancel,
		}
	},
}
</script>
