<template>
	<v-dialog
		v-model="isOpenMyPagePostContentsEditDialog"
		persistent
		max-width="800"
		:fullscreen="isSmAndDown"
	>
		<v-card class="justify-center align-center">
			<v-card-title class="d-flex justify-space-between py-3 font-weight-bold">
				<div>
					<span class="mr-2">콘텐츠</span>
				</div>
				<v-btn icon class="px-0" @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider />

			<v-card-text class="pt-0">
				<div class="mb-4">
					<market-page-post-contents-swiper :post="post" :can-download="true" />
					<div class="pl-1 mb-2">
						<div class="font-weight-bold">
							<span>
								{{
									post.subject && post.subject.lounge
										? resoloveSubjectLoungeType(post.subject.lounge)
										: resolvePostType(post.type)
								}}
								{{ post.postingNumber }}
							</span>
							<span v-if="post.subject" class="mx-1">&#62;</span>
							<span v-if="post.subject">{{ post.subject.title }}</span>
						</div>
					</div>
					<v-textarea
						v-model="post.content"
						placeholder="문구 입력..."
						outlined
						dense
						hide-details=""
						style="background-color: white"
						readonly
					/>
				</div>
			</v-card-text>
		</v-card>
		<my-page-post-my-bottom-sheet
			:sheet.sync="sheet"
			:duplicate-posts="duplicatePosts"
		/>
	</v-dialog>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'

import {
	resolveContentsMarketStatus,
	resolveContentsMarketStatusVariant,
} from '@core/utils/filter'
import { resolvePostType, resoloveSubjectLoungeType } from '@/filter'
import { getVuetify } from '@core/utils'
import { contentsMarketStatus, postType } from '@/helpers'

import MarketPostService from '@/services/MarketPostService'

import MyPagePostMyBottomSheet from './MyPagePostMyBottomSheet.vue'
import MarketPagePostContentsSwiper from './components/MarketPagePostContentsSwiper.vue'

export default {
	components: {
		MyPagePostMyBottomSheet,
		MarketPagePostContentsSwiper,
	},
	props: {
		postId: {
			type: [String, Number],
		},
		isOpenMyPagePostContentsEditDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		const $vuetify = getVuetify()
		const refInputEl = ref(null)
		const isUploading = ref(false)
		const newFiles = ref([])
		const post = ref({})
		const originPost = ref(null)
		const sheet = ref(false)
		const duplicatePosts = ref([])
		const isEditable = ref(false)
		const breakpoints = ref({
			600: {
				fixedHeight: '320px',
				arrowsOutside: false,
			},
			959: {
				arrowsOutside: false,
			},
		})

		const getDuplicateFile = async etags => {
			try {
				const data = await MarketPostService.getAllDetail({
					type: postType.CONTEST2.value,
					etags: etags,
					includes_staff_items: true,
				})
				if (data.posts.length > 1) {
					const _posts = data.posts
					duplicatePosts.value = [..._posts]
				}
			} catch (e) {
				console.error(e)
			}
			return true
		}

		const getMyMarketPostDetail = async () => {
			try {
				post.value = await MarketPostService.getDetail(props.postId)

				post.value.content = post.value.content.replace(/<br \/>/g, '\n')

				const _duplicateFiles = []
				for (const [_, file] of Object.entries(post.value.files)) {
					_duplicateFiles.push(file.etag)
				}
				await getDuplicateFile(_duplicateFiles.join())

				originPost.value = duplicatePosts.value[duplicatePosts.value.length - 1]
				const findIndex = duplicatePosts.value.findIndex(
					e => e.id == post.value.id,
				)
				duplicatePosts.value.splice(findIndex, 1)
			} catch (e) {
				console.error(e)
			}
		}

		watch(
			() => props.isOpenMyPagePostContentsEditDialog,
			async currentValue => {
				if (currentValue) {
					await getMyMarketPostDetail()
				}
			},
			{
				immediate: true,
			},
		)

		const closeDialog = () => {
			emit('update:is-open-my-page-post-contents-edit-dialog', false)
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		return {
			post,
			sheet,
			newFiles,
			isEditable,
			refInputEl,
			originPost,
			breakpoints,
			isSmAndDown,
			isUploading,
			duplicatePosts,
			contentsMarketStatus,

			resolvePostType,
			resoloveSubjectLoungeType,
			resolveContentsMarketStatus,
			resolveContentsMarketStatusVariant,

			closeDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.uploaded-file {
	img,
	video {
		width: 100%;
		max-height: 200px;
		object-fit: contain;
	}
}

.status-chip {
	position: absolute;
	right: 5px;
	top: 5px;
}

.grid-container {
	display: grid !important;
	gap: 24px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;

	@media (max-width: 960px) {
		gap: 10px;
	}

	@media (max-width: 768px) {
		gap: 7px;
	}

	@media (max-width: 595px) {
		gap: 3px;
	}

	.grid-item {
		max-height: 300px;
		position: relative;

		.action-button {
			button {
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		div {
			width: 100%;

			.v-image {
				height: 300px;
			}

			@media (max-width: 960px) {
				.v-image {
					height: 250px;
				}
			}

			@media (max-width: 768px) {
				.v-image {
					height: 180px;
				}
			}

			@media (max-width: 571px) {
				.v-image {
					height: 130px;
				}
			}

			@media (max-width: 365px) {
				.v-image {
					height: 85px;
				}
			}
		}
	}
}
</style>
