<template>
	<div>
		<v-dialog v-model="dialog" :fullscreen="isSmAndDown">
			<template v-slot:activator="{ on, attrs }">
				<v-btn small text v-bind="attrs" v-on="on">
					<span class="mr-1" style="letter-spacing: -1px">
						<strong>
							{{ postCommentsCount }}
						</strong>
					</span>
					<v-badge
						color="error"
						:content="unreadPostCommentsCount"
						:value="unreadPostCommentsCount"
					>
						<v-icon small>mdi-message-outline</v-icon>
					</v-badge>
				</v-btn>
			</template>

			<v-card>
				<v-card-title class="d-flex justify-space-between">
					<div>
						<v-icon>mdi-message-outline</v-icon>
						코멘트
					</div>
					<v-btn icon small text @click="dialog = !dialog">
						<v-icon small>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-list>
					<v-list-item
						v-for="(postComment, index) in postComments"
						:key="'comment-' + index"
						style="align-items: start"
					>
						<v-list-item-avatar size="18">
							<v-img
								:src="
									postComment.user.publicPictureUrl ||
									require('@/assets/images/avatars/default.svg')
								"
							/>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-sm text-wrap">
								<strong>
									{{
										postComment.user.marketerRole === 'ADMIN'
											? '관리자'
											: postComment.user.uid
									}}
								</strong>
								<span class="text-xs">
									{{ getTimeDiff(postComment.createdAt) }}
								</span>
								<div>
									{{ postComment.title }}
								</div>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider />
				<v-card-actions class="d-flex justify-end">
					<v-textarea
						v-model="comment"
						placeholder="답변 달기..."
						outlined
						hide-details=""
						rows="2"
						style="background-color: white"
					/>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import dayjs from 'dayjs'
import { getVuetify } from '@core/utils'
import relativeTime from 'dayjs/plugin/relativeTime'

import MarketPostService from '@/services/MarketPostService'

dayjs.extend(relativeTime)

export default {
	props: {
		postId: {
			type: Number,
		},
		postCommentsCount: {
			type: Number,
		},
		unreadPostCommentsCount: {
			type: Number,
		},
	},
	setup(props) {
		const $vuetify = getVuetify()
		const postComments = ref([])
		const comment = ref('')
		const dialog = ref(false)

		const getDetail = async () => {
			try {
				const data = await MarketPostService.getDetail(props.postId)

				postComments.value = data.comments
			} catch (e) {
				console.log(e)
			}
		}

		const getTimeDiff = date => {
			return dayjs(date).from(dayjs())
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => dialog.value,
			async currentValue => {
				if (currentValue) {
					await getDetail()
				}
			},
			{
				immediate: true,
			},
		)

		return {
			dialog,
			comment,
			isSmAndDown,
			postComments,

			getTimeDiff,
		}
	},
}
</script>
