var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swiper',{ref:"swiperRef",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.virtualData.slides),function(file,index){return _c('swiper-slide',{key:file.id,style:({ left: _vm.virtualData.offset + 'px' }),attrs:{"virtual-index":file.id}},[(_vm.canDownload)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"left":"5px","top":"5px","z-index":"99"},attrs:{"x-small":"","absolute":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(file)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("개별 다운로드"+_vm._s(index))])]):_vm._e(),(
				_vm.post.duplicatePosts &&
				_vm.post.duplicatePosts.length > 0 &&
				_vm.post.originPost &&
				_vm.post.originPost.id !== _vm.post.id
			)?_c('v-chip',{staticClass:"status-chip",staticStyle:{"top":"30px","z-index":"99"},attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('open-sheet', _vm.post.duplicatePosts)}}},[_vm._v(" 중복 "+_vm._s(_vm.post.duplicatePosts.length)+"건 ")]):_vm._e(),_c('v-chip',{staticClass:"status-chip",attrs:{"small":"","color":_vm.resolveContentsMarketStatusVariant(_vm.post.result)}},[_c('span',{class:{
					'd-none':
						_vm.post.result === _vm.contentsMarketStatus.APPROVED.value &&
						_vm.isSmAndDown,
				}},[_vm._v(" "+_vm._s(_vm.resolveContentsMarketStatus(_vm.post.result))+" ")]),(_vm.post.result === _vm.contentsMarketStatus.APPROVED.value)?_c('span',{staticClass:"ml-1"},[_c('strong',[_vm._v(_vm._s(_vm._f("commaFormat")(_vm.post.resultJson.payment)))]),_vm._v(" 원 ")]):_vm._e()]),(!_vm.isImage(file.url))?_c('v-chip',{staticStyle:{"top":"40px"},attrs:{"small":"","absolute":"","right":""}},[_c('v-icon',[_vm._v("mdi-movie-play-outline")])],1):_vm._e(),(_vm.isImage(file.url))?_c('div',{staticClass:"text-center cursor-pointer content-wrapper"},[_c('img',{directives:[{name:"lazyload",rawName:"v-lazyload"}],staticClass:"swiper-lazy content",staticStyle:{"height":"300px"},attrs:{"data-src":("" + (_vm.renderUrl(file.url))),"loading":"lazy"},on:{"error":function($event){$event.target.src = file.url},"click":function($event){return _vm.$emit('open-media-dialog', file.url)}}})]):_c('div',{staticClass:"content-wrapper"},[_c('video',{staticStyle:{"width":"100%","height":"300px"},attrs:{"controls":"","controlsList":"nodownload"}},[_c('source',{staticClass:"content",attrs:{"src":file.url}})])]),_c('v-btn',{staticClass:"arrow-button-first",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.moveToFirst()}}},[_vm._v(" 처음 ")]),_c('v-btn',{staticClass:"arrow-button-last",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.moveToLast()}}},[_vm._v(" 끝 ")])],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }