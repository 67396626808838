<template>
	<v-bottom-sheet v-model="sheet" persistent scrollable>
		<v-sheet class="text-right">
			<v-btn class="mt-6" text @click="$emit('update:sheet', false)">
				닫기
			</v-btn>
			<div>
				<div class="grid-container" v-if="duplicatePosts.length > 0">
					<v-card
						class="grid-item cursor-pointer"
						v-for="post in duplicatePosts"
						:key="'post-' + post.id"
						elevation="1"
					>
						<div
							class="px-3"
							style="background-color: white; width: 100%"
							:class="isSmAndDown ? 'px-1 py-0 text-xs pt-1' : 'pa-2'"
						>
							<div class="d-flex align-center justify-space-between">
								<div
									class="d-flex align-center"
									style="overflow: hidden; text-overflow: ellipsis"
									:style="{ 'width: 70%;': isSmAndDown }"
									v-if="post && post.writerUser"
								>
									<v-avatar :size="isSmAndDown ? 14 : 26">
										<v-img
											:src="
												post.writerUser.publicPictureUrl ||
												require('@/assets/images/avatars/default.svg')
											"
										/>
									</v-avatar>
									<div class="d-flex flex-column">
										<span
											class="font-weight-bold ml-1 text-left"
											:class="isSmAndDown ? 'text-xs' : 'text-sm'"
										>
											{{ post.writerUser.uid }}
										</span>
										<span
											class="ml-1"
											:class="isSmAndDown ? 'text-xs' : 'text-xs'"
											style="letter-spacing: -0.4px"
										>
											<span v-if="isSmAndDown">
												{{ post.createdAt | dateKOSimpleDotFormat }}
											</span>
											<span v-else>
												{{ post.createdAt | dateKOFormat }}
											</span>
										</span>
									</div>
								</div>
								<div>
									<v-btn
										small
										text
										@click="openPostDetailDialog(post.id)"
										:icon="isSmAndDown"
										class="px-0"
									>
										<template v-if="isSmAndDown">
											<v-icon small>mdi-arrow-expand</v-icon>
										</template>
									</v-btn>
								</div>
							</div>
						</div>

						<market-page-post-contents-swiper
							:post="post"
							:can-download="true"
						/>

						<div class="wrapper-content">
							<div class="font-weight-bold">
								콘텐츠 {{ post.postingNumber }}
							</div>
							<div class="content">{{ post.content }}</div>
						</div>
					</v-card>
				</div>
			</div>
		</v-sheet>
	</v-bottom-sheet>
</template>
<script>
import { computed } from '@vue/composition-api'

import {
	resolveContentsMarketStatus,
	resolveContentsMarketStatusVariant,
} from '@core/utils/filter'
import { getVuetify } from '@core/utils'
import { contentsMarketStatus } from '@/helpers'

import MarketPagePostContentsSwiper from './components/MarketPagePostContentsSwiper.vue'

const S3_URL = process.env.VUE_APP_AWS_S3_URL
const CDN_URL = process.env.VUE_APP_AWS_CDN_URL

export default {
	components: {
		MarketPagePostContentsSwiper,
	},
	props: {
		sheet: {
			type: Boolean,
			required: true,
		},
		duplicatePosts: {
			type: Array,
		},
	},
	setup() {
		const $vuetify = getVuetify()
		const isImage = url => {
			return url.match(/\.(jpeg|jpg|gif|png|)$/) != null
		}

		const replaceUrl = url => {
			if (url.match(/\.(heic)$/i)) {
				return ''
			}

			if (url.includes(S3_URL)) {
				url = url.replace(S3_URL, CDN_URL)
			}
			return url
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		return {
			isImage,
			replaceUrl,
			contentsMarketStatus,

			isSmAndDown,

			resolveContentsMarketStatus,
			resolveContentsMarketStatusVariant,
		}
	},
}
</script>
<style lang="scss" scoped>
.scrollable {
	overflow-y: auto;
}

.status-chip {
	position: absolute;
	right: 5px;
	top: 5px;
}

::v-deep {
	.v-bottom-sheet.v-dialog {
		overflow: scroll;
	}
}

.v-sheet {
	height: 100%;
}

.wrapper-content {
	font-size: 14px;
	padding: 16px 16px 0;

	@media (max-width: 674px) {
		padding: 8px 8px 0;
	}
}

div.actions {
	padding-left: 12px;
	padding-right: 12px;

	.v-btn {
		width: 49.5%;
	}
}

.grid-container {
	display: grid;
	gap: 12px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;
	overflow-y: scroll;

	@media (max-width: 960px) {
		gap: 8px;
	}

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 6px;
	}

	@media (max-width: 694px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	@media (max-width: 595px) {
		gap: 3px;
	}

	@media (max-width: 470px) {
	}

	@media (max-width: 367px) {
	}

	.grid-item {
		.status-chip {
			position: absolute;
			right: 5px;
			top: 5px;
		}

		@media (max-width: 960px) {
			min-height: 380px;
		}

		@media (max-width: 768px) {
			min-height: 275px;
		}

		@media (max-width: 595px) {
			min-height: 215px;
		}

		@media (max-width: 414px) {
			min-height: 145px;
		}

		// ipad landscape
		@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
			min-height: 275px;
		}

		// ipad portrait
		@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
			min-height: 295px;
		}

		.v-skeleton-loader {
			height: 355px !important;

			@media (max-width: 674px) {
				height: 36vw !important;
			}
		}

		::v-deep .v-skeleton-loader__list-item-avatar {
			.v-skeleton-loader__avatar {
				@media (max-width: 571px) {
					height: 15px;
					width: 15px;
				}
			}
		}
	}
}
</style>
