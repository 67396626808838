import axios from '@/api/axios'

export default {
	getAllMeDetail(params) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({
					method: 'GET',
					url: '/market-posts/me/detail',
					headers: {
						'X-Comments': true,
						'X-Content-Market-Statistics': true,
					},
					params,
				})

				resolve({
					posts: response.data,
					totalPages: response.headers['x-total-pages-count'],
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getAllDetail(params) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({
					method: 'GET',
					url: `/market-posts/detail`,
					params,
				})

				resolve({
					posts: response.data,
					totalPages: response.headers['x-total-pages-count'],
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-posts/${id}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}
